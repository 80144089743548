// This file contains details about the meter properties that this project will have. It was
// originally created to fix a circular dependency that existed between
// site-consts -> logger -> site-consts by removing all imports from site-consts.
import appLogger from './logger';

const mlogger = appLogger.getLogger('site-consts');
// mlogger.logLevel = mlogger.loggerLevels.debug;

const ROUTE_KEY = 'Route Name';
const NUM_DETAILS_SHOW = 3;
// const METER_ID_RE = /^22003[0-9]{4}$/;
// const TRANSMITTER_ID_RE = /^130[0-9]{6}$/;

const PROP_TYPES = {
    BOOL: Boolean,
    STRING: String,
    NUMBER: Number,
    SELECT: 'Select',
    SELECT_MULTI: 'Select Multiple',
    SELECT_EDITABLE: 'Select Editable',
    BARCODE: 'Barcode',
    TEXTAREA: 'Text Area',
    DATE: 'Date',
    ISO_TIMESTAMP: 'ISO Timestamp',
    INSTALL_TYPE: 'Install Type',
    TIMER_TEXT: 'Timer Text',
    CREW_WITH_HOURS: 'Crew With Hours',
};

const FEATURE_TOGGLES = {
    GPS_EDITABLE: false,
    SHOW_GPS: false,
};

// The meter props is a list of all the properties we have for the meters
//   key - The meter property name that contains the value for this property meter[prop.key]
//   type - The type of the prop. Possible values: Boolean, String, Number
//   TODO Complete this list
const METER_PROPS = [
    // Objects in this array can have the following properties:
    //   key: The lookup key in the meter object for this property's value.
    //   name: If something other than the key should be used for the display name.
    //   align: Whether to align this value when displaying in a table.
    //   modifiable: If false, this property is shown in the meter details at the top. If true,
    //     a field is given in the editable meter details section for the user to modify.
    //   type: One of PropTypes in ../shared/PropTypes.mjs indicating the type of value
    //     (bool, etc.).
    //   rememberLast: Indicates if the app should remember the last value saved for this field and
    //     then apply that value as the default.
    //   crew: Specific to the CREW_WITH_HOURS type. TODO Move this to choices.
    //   onSave: A function that's called whenvever a value is saved for this field.
    //   defaultValue: A value that indicates the default value for this field and used if saved
    //     without being modified. Can also be a funtion that returns a value for the default.
    //   htmlType: A HTML type to be placed in the type attribute.
    //   validationFunc: A function to validate the value given in this field. Needs to return true
    //     or a string.
    //   choices: A list of choices to choose from for the SELECT, SELECT_MULTI, and SELECT_EDITABLE
    //     types.
    //   optional: True/false indicating if the value is optional
    //   condition: A function that indicates if the field should be shown.

    { key: 'Account #' },
    { key: 'Service Address', align: 'left' },
    { key: 'Meter Size' },
    { key: 'Southern Software Meter ID' },
    { key: 'Full Name' },
    { key: 'Previous Install Date' },
    { key: 'Current Reading' },
    { key: 'Current Reading Date' },
    { key: 'Previous Reading' },
    { key: 'Previous Reading Date' },
    { key: 'Avg Usage Amount' },
    { key: 'Outgoing Meter Number' },
    { key: 'Outgoing Meter Manufacturer' },
    { key: 'Meter Type' },
    { key: 'Mtr IDNo' },
    { key: 'Number of Users' },
    { key: 'Outgoing Meter Number of Dials' },
    { key: 'Outgoing Meter Multiplier' },
    { key: 'Outgoing Meter Units' },
    { key: 'Group Code' },
    { key: 'Customer ID' },
    { key: 'Idx' },
    { key: 'Route Name', filterName: 'Routes' },
    { key: 'Description' },
    { key: 'Latitude' },
    { key: 'Longitude' },
    // {
    //     key: 'Crew Members',
    //     modifiable: true,
    //     type: PROP_TYPES.CREW_WITH_HOURS,
    //     rememberLast: true,
    //     crew: [
    //         'Greg Austin', 'Sherman Austin', 'CJ Boothe', 'Taylor Campbell', 'Brian Cook',
    //         'Devin Fowler', 'Hunter McCoy', 'Casey Perkins',
    //     ],
    //     onSave: function onCrewMembersSave(meter, lastUsedSettings) {
    //         const flogger = mlogger.getLogger('crew-members-onsave');
    //         flogger.logLevel = flogger.loggerLevels.debugall;
    //         // We only want output from this function when we're debugging issues, so we'll
    //         // log at a debug or debugall level in this function.
    //         flogger.debug(`Saving crew members (${JSON.stringify(meter['Crew Members'])}) for our default value`);
    //         if (!Array.isArray(meter['Crew Members'])) {
    //             lastUsedSettings.lastCrewSelected = undefined;
    //             return;
    //         }
    //         const lastCrew = [];
    //         for (let i = 0; i < meter['Crew Members'].length; i++) {
    //             flogger.debugall(`Massaging crew member ${meter['Crew Members'][i]}`);
    //             if (Array.isArray(meter['Crew Members'][i])) {
    //                 // If our crew member is an array, its probably an array of [Name, Hours] and
    //                 // we only want the name.
    //                 flogger.debugall('Crew member is an array');
    //                 lastCrew.push(meter['Crew Members'][i][0]);
    //             } else if (meter['Crew Members'][i].Name) {
    //                 // If our crew member is an object with a Name and Hours, grab just the name
    //                 // and set the hours to 0 for our default value
    //                 flogger.debugall('Crew member is an object with a Name prop');
    //                 lastCrew.push({
    //                     Name: meter['Crew Members'][i].Name,
    //                     Hours: 0,
    //                 });
    //             } else {
    //                 flogger.debugall('Crew member should be just strings');
    //                 lastCrew.push(meter['Crew Members'][i]);
    //             }
    //         }
    //         flogger.debug(`Value used for default value: ${JSON.stringify(lastCrew)}`);
    //         lastUsedSettings.lastCrewSelected = lastCrew;
    //     },
    //     defaultValue: function crewMemberDefault(lastUsedSettings) {
    //         if (lastUsedSettings && lastUsedSettings.lastCrewSelected) {
    //             return lastUsedSettings.lastCrewSelected;
    //         }
    //         return [];
    //     },
    // }, {
    //     key: 'Parts Needed',
    //     modifiable: true,
    //     type: PROP_TYPES.SELECT_MULTI,
    //     choices: [
    //         '7" Resetter',
    //         '9" Resetter',
    //         '12" Resetter',
    //         'Retro Resetter',
    //         '3/4" Meter Adapter',
    //         'Tail Pieces',
    //     ],
    //     optional: true,
    // }, {
    //     key: 'Parts Used',
    //     modifiable: true,
    //     type: PROP_TYPES.SELECT_MULTI,
    //     choices: [
    //         '7" Resetter',
    //         '9" Resetter',
    //         '12" Resetter',
    //         'Retro Resetter',
    //         '3/4" Meter Adapter',
    //         'Tail Pieces',
    //     ],
    //     optional: true,
    // }, {
    //     key: 'Need to Schedule Water Outage',
    //     type: PROP_TYPES.BOOL,
    //     defaultValue: false,
    //     modifiable: true,
    // }, {
    //     key: 'Install Type',
    //     modifiable: true,
    //     type: PROP_TYPES.SELECT,
    //     choices: [
    //         { text: 'Standard Meter Replacement', value: 'Standard Meter Replacement' },
    //         { text: 'Hourly Rate', value: 'Hourly Rate' },
    //     ],
    //     defaultValue: 'Standard Meter Replacement',
    // }, {
    //     key: 'Install Hours',
    //     modifiable: true,
    //     type: PROP_TYPES.TIMER_TEXT,
    //     condition: function showInstallHours(meter) {
    //         // Only show this field if they've selected Hourly Rate for the Install Type
    //         return meter['Install Type'] === 'Hourly Rate';
    //     },
    //     completed: function installHoursCompleted(meter) {
    //         if (meter['Install Type'] === 'Hourly Rate') {
    //             if (meter['Install Hours'] > 0) {
    //                 return true;
    //             }
    //             return false;
    //         }
    //         return true;
    //     },
    //     validationFunc: function installHoursValid(value, meter) {
    //         if (meter['Install Type'] === 'Hourly Rate') {
    //             if (value > 0) {
    //                 return true;
    //             }
    //             return 'If Install Type is Hourly Rate, a value must be entered in '
    //                 + Install Hours.';
    //         }
    //         return true;
    //     },
    // },
    {
        key: 'Outgoing Meter Reading',
        modifiable: true,
        type: PROP_TYPES.NUMBER,
        htmlType: 'number',
        // TODO Create a Meter Reading type eliminates this function
        validationFunc: function outgoingMeterReadingValid(value, meter) {
            if (!value) {
                return 'A value must be entered.';
            }
            if (value < meter['Previous Reading']) {
                return 'The meter reading is less than the previous reading.';
            }
            if (value > meter['Previous Reading'] + 1000) {
                return 'The meter reading shows a consumption of more than 1000 above the previous reading. Is it correct?';
            }
            // The current import file doesn't have a high consumption value, so we don't have a
            // way to check if the value is too high.
            // if (value > this.meter['Last Meter Reading'] + this.meter['High Consumption']) {
            //     return 'This meter consumed more than it has ever previously consumed. Are you
            // sure you read it correctly?';
            // }
            return true;
        },
    }, {
        key: 'Meter Size',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: ['5/8"', '3/4"', '1"', '1.5"', '2"', '3"', 'HYDRANT'],
    }, {
        key: 'Inlet Pipe Type',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: ['Lead', 'Copper', 'Galvanize', 'Plastic', 'Unknown'],
    }, {
        key: 'Outlet Pipe Type',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        choices: ['Lead', 'Copper', 'Galvanize', 'Plastic', 'Unknown'],
    }, {
        key: 'New Meter Serial',
        modifiable: true,
        type: PROP_TYPES.BARCODE,
        // validationFunc: function newMeterSerialValid(value) {
        //     if (!METER_ID_RE.test(value)) {
        //         return 'The new meter serial number is expected to be of the format 22003XXXX';
        //     }
        //     return true;
        // },
    // }, {
    //     key: 'New Meter Reading',
    //     modifiable: true,
    //     type: PROP_TYPES.NUMBER,
    //     defaultValue: 0,
    //     // TODO Create a Meter Reading type eliminates this function
    //     validationFunc: function newMeterReadingValid(value) {
    //         // if (this.meter['Install Type'] !== 'Replace Meter') {
    //         //     return true;
    //         // }
    //         if (value === undefined || value === '') {
    //             return 'A value must be entered.';
    //         }
    //         if (value > 10) {
    //             return `Most readings on new meters are less than 10. Are you sure the value of ${value} is correct?`;
    //         }
    //         return true;
    //     },
    //     htmlType: 'number',
    }, {
        key: 'Transmitter ID',
        modifiable: true,
        type: PROP_TYPES.BARCODE,
        // TODO Add a prop like validationExpr that will be the regular expression to test against
        // validationFunc: function transmitterIdValid(value) {
        //     if (!TRANSMITTER_ID_RE.test(value)) {
        //         return 'The transmitter ID is expected to be of the format 130XXXXXX';
        //     }
        //     return true;
        // },
    }, {
        key: 'Install Date',
        modifiable: true,
        type: PROP_TYPES.DATE,
        defaultValue: function dateDefault() {
            // The date component presents a default value. But, we need this function so the
            // save function can grab a default value for the save.
            const now = new Date();
            return `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
        },
    },
    { key: 'Uninstallable', type: PROP_TYPES.BOOL, defaultValue: false, modifiable: true },
    {
        key: 'Town Attention Needed', type: PROP_TYPES.BOOL, defaultValue: false, modifiable: true,
    }, {
        key: 'Town Attention Needed Problem Type',
        modifiable: true,
        condition: function showProblemType(meter) {
            // Only show this field if they've selected Hourly Rate for the Install Type
            return meter['Town Attention Needed'] === true;
        },
        type: PROP_TYPES.SELECT,
        choices: ['Meter', 'Pipe Identification', 'Both'],
    },
    { key: 'Notes', modifiable: true, type: PROP_TYPES.TEXTAREA, optional: true },
    { key: 'Version', name: 'Last Updated', type: PROP_TYPES.ISO_TIMESTAMP },
];

const PHOTOS = [
    {
    //     name: 'Area Before',
    //     shortName: 'pre-exchange',
    //     key: 'Photo URL Pre-Exchange',
    // }, {
        name: 'Final Read',
        shortName: 'final-read',
        key: 'Photo URL Final Read',
    }, {
        name: 'Meter & Tx #',
        shortName: 'post-exchange',
        key: 'Photo URL Post Exchange',
    }, {
        name: 'Inlet Pipe',
        shortName: 'inlet-pipe',
        key: 'Photo URL Inlet Pipe',
    }, {
        name: 'Outlet Pipe',
        shortName: 'outlet-pipe',
        key: 'Photo URL Outlet Pipe',
    },
];

// Everything else is pre-computed arrays that can be overriden with custom arrays if needed

const METER_PROPS_BY_KEY = {};
const METER_PROPS_BY_NAME = {};
for (let i = 0; i < METER_PROPS.length; i++) {
    // Apply our default values
    if (METER_PROPS[i].name === undefined) {
        // If we didn't specify a name, the name is the same as the key
        METER_PROPS[i].name = METER_PROPS[i].key;
    }
    if (METER_PROPS[i].type === undefined) {
        // If we didn't specify a type, set as string. This is needed for sorting.
        METER_PROPS[i].type = PROP_TYPES.STRING;
    }
    // Text and value are used by the Vuetify data table header objects
    METER_PROPS[i].text = METER_PROPS[i].name;
    METER_PROPS[i].value = METER_PROPS[i].key;
    if (METER_PROPS[i].modifiable === undefined) {
        METER_PROPS[i].modifiable = false;
    }
    METER_PROPS_BY_KEY[METER_PROPS[i].key] = METER_PROPS[i];
    METER_PROPS_BY_NAME[METER_PROPS[i].name] = METER_PROPS[i];
}

const METER_DETAILS_PROPS = METER_PROPS.filter((prop) => !prop.modifiable);

const METER_EDIT_PROPS = METER_PROPS.filter((prop) => prop.modifiable);

const INITIAL_SORT_PROPS = [
    METER_PROPS_BY_KEY['Route Name'],
    METER_PROPS_BY_KEY['Account #'],
];

const METER_LIST_COLS = [
    // METER_PROPS_BY_KEY['Route Name'],
    METER_PROPS_BY_KEY['Southern Software Meter ID'],
    METER_PROPS_BY_KEY['Account #'],
    METER_PROPS_BY_KEY['Service Address'],
];

const PHOTOS_BY_TYPE = {};
for (let i = 0; i < PHOTOS.length; i++) {
    PHOTOS_BY_TYPE[PHOTOS[i].shortName] = PHOTOS[i];
}

// To use this file in a CommonJS environment replace export with module.exports =.
export {
    ROUTE_KEY, PROP_TYPES,
    FEATURE_TOGGLES, METER_PROPS, METER_PROPS_BY_KEY,
    METER_PROPS_BY_NAME, METER_DETAILS_PROPS, NUM_DETAILS_SHOW, METER_EDIT_PROPS,
    INITIAL_SORT_PROPS, METER_LIST_COLS, PHOTOS, PHOTOS_BY_TYPE,
};
